.App {
  /* text-align: center; */
  display: flex;
  flex-direction: column;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #081c42;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.RowStyle {
  display: flex;
  flex-direction: row;
  /* justify-content: space-evenly; */
  justify-content: space-between;
}

.redBall {
  width: 40px;
  height: 40px;
  background-color: red;
  color: white;
  text-align: center;
  font-size: 20px;
  border-radius: 50%;
  line-height: 40px;
}

.blueBall {
  width: 40px;
  height: 40px;
  background-color: blue;
  color: white;
  font-size: 20px;
  text-align: center;
  border-radius: 50%;
  line-height: 40px;
}

.circle {
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
  margin-top: 10px;
}

.bottomA {
  text-align: center;
  position: fixed;
  bottom: 15px;
  left: 0px;
  right: 0px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.lotteryNum {
  height: 48px;
  font-size: 22px;
  font-weight: bold;
  border-radius: 33px;
  color: #ffffff;
  text-align: center;
  line-height: 48px;
  z-index: 2;
  position: relative;
}

.list-item{
  border-bottom: 1px solid #d8d8d8;
  padding: 10px 12px;
}
